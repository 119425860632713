.error {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.click {
  max-width: 100px;
  margin-left: var(--space-1);
  margin-right: var(--space-1);
}
