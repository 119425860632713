.login {
  height: 100%;
  width: 100%;
  background-color: #2658BB;
}

.login__container {
  height: 100%;
  width: 60%;
}

.card {
  background-color: white;
  padding: var(--space-8) 0 0 0;
  border-radius: var(--space-4);
  width: 100%;
}

.footer {
  padding: var(--space-4);
  background-color: var(--lightest_gray);
  border-bottom-right-radius: var(--space-4);
  border-bottom-left-radius: var(--space-4);
}

.description {
  margin-left: var(--space-4);
}

.title {
  padding: 0 var(--space-8);
}

.logo {
  margin-top: var(--space-8);
}