.verticle-border {
  position: relative;
  width: 1px;
  border: 1px solid var(--border_color, #f0f0f0);
  height: 220px;
  margin: 0 var(--space-4);
}

.content {
  position: absolute;
  width: 20px;
  background-color: white;
}