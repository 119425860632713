.login-form__input {
  margin-bottom: var(--space-4);
}

.login-form {
  padding: var(--space-8) var(--space-16);
}

.login-form__animation-wrapper {
  max-height: 240px;
}