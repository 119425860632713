.button {
  border: 1px solid gray;
  border-radius: 4px;
  margin-left: 1rem;
  cursor: pointer;
}

.snapshot {
  margin-top: 1rem;
}

.container {
  max-height: 300px;
  overflow-y: auto;
}

.title--open {
  width: 100%;
  z-index: 10000;
  height: 300px;
  overflow-y: auto;
}
