body {
  margin: 0;
  height: 100vh;
}

#app{
  height: 100%;
}

.portal h1 {
  text-align: center !important;
  font-size: 40px !important;
  margin-bottom: 35px;
}

.capitalize {
  text-transform: capitalize;
}


:root {
  --space-14: 3.5rem;
  --space-16: 4rem;
  --space-20: 5rem;
  --space-24: 6rem;
  --space-28: 7rem;
  --space-32: 8rem;
  --space-36: 9rem;
  --space-40: 10rem;
  --space-44: 11rem;
  --space-48: 12rem;
  --space-52: 13rem;
  --space-56: 14rem;
  --space-60: 15rem;
  --space-64: 16rem;
  --space-72: 18rem;
  --space-80: 20rem;
  --space-96: 24rem;
  --space-px: 1px;
  --container: 92vh;
}

.tr-select .ant-select-selector {
  border-radius: var(--border_radius);
}

.title {
  background-color: white;
  padding: var(--space-6) var(--space-8);
  margin-bottom: var(--space-1);
}

.title-nopadding {
  background-color: white;
  margin-bottom: var(--space-1);
}

.title-padding-top {
  background-color: white;
  margin-bottom: var(--space-1);
  padding-top: 32px;
}


.ant-btn > .ant-btn-loading-icon {
  display: none;
}
