.error-text {
  margin: var(--space-6) var(--space-6) var(--space-3) var(--space-6);
}

.error-message {
  border: 1px solid var(--icon_red_dark);
  width: fit-content;
  display: flex;
  justify-content: center;
  padding: var(--space-2) var(--space-4);
  border-radius: 4px;
  background-color: var(--lightest_red);
  margin-top: var(--space-4);
}

.button {
  margin-left: var(--space-4);
  padding: 0 var(--space-1);
}
